<template>
  
  <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-1">
    <item-text title="UUID" :text="item.uuid" />
  </div>
  
  <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
    <item-text title="Ražotājs" :text="item.catalog_item.manufacturer" />
  </div>
  
  <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
    <item-text title="Pielietojums" :text="item.purpose.name" />
  </div>
  
  <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
    <item-text title="Kategorija" :text="item.catalog_item.category" />
  </div>
  
  <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
    <item-text title="Īpašnieks" :text="item.customer.name" />
  </div>

  <template v-if="item.location">
    <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
      <item-text title="Atrašanās vieta" :text="item.location" />
    </div>
  </template>
  
  <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
    <template v-if="item.owner_branch">
      <item-text title="Filiāle" :text="item.owner_branch.name" />
    </template>
  </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "InventoryItemsListItemDetails",
  components: {
    ItemText,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
</script>

<style>

</style>