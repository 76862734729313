<template>
  <item-card :url="`/stock/inventory_items/${item.uuid}`" contentClass="grid grid-cols-12">
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        {{item.catalog_item.model}}
      </h3>
    </template>

    <template v-slot:buttons>
      <template v-if="editableItem.id !== item.id">

        <Button icon="pencil" @click="editItem(item)" />

        <Button icon="delete" @click="deleteInventoryItem(item.id)" />

      </template>
    </template>

    <template v-slot:content>
      <template v-if="editableItem.id !== item.id">
        <InventoryItemsListItemDetails :item="item" />
      </template>
    </template>

    <template v-slot:additional>
      <template v-if="editableItem.id === item.id">
        <EditInventoryItem :item="item" :page="inventoryItems.meta.current_page" :filter="selectedFilter" :selectedMainBranch="selectedMainBranch" />
      </template>
    </template>
  </item-card>
</template>

<script>
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import ItemCard from "@/components/Components/ItemCard"
import InventoryItemsListItemDetails from "@/components/Stock/InventoryItems/InventoryItemsListItemDetails"
import EditInventoryItem from "@/components/Stock/InventoryItems/EditInventoryItem"

export default {
  name: "InventoryItemsListItem",
  components: {
    ItemCard,
    InventoryItemsListItemDetails,
    EditInventoryItem,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    inventoryItems: {
      type: Object,
      required: true
    },
    selectedFilter: {
      required: true,
    },
    selectedMainBranch: {},
  },
  computed: {
    ...mapGetters({
      editableItem: 'editableItem'
    })
  },
  methods: {
    editItem(item){
      this.$store.dispatch('setEditableItem', item)
    },
    deleteInventoryItem(itemId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteInventoryItem', itemId)
      }
    },
  }
}
</script>

<style>

</style>