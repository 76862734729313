<template>

    <form v-if="form" class="space-y-6 w-full pb-4">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
            <div class="col-span-1">
                <Input name="uuid" placeholder="UUID" v-model="form.uuid" :errors="errors.uuid"/>
            </div>

            <div class="col-span-1">
                <Input name="serial_nr" placeholder="Sērijas nr." v-model="form.serial_nr" :errors="errors.serial_nr"/>
            </div>

            <div class="col-span-1">
                <Input name="name" placeholder="Nosaukums" v-model="form.name" :errors="errors.name"/>
            </div>

            <div class="col-span-1">
                <Input name="cost" placeholder="Vērtība EUR" v-model="form.cost" :errors="errors.cost"/>
            </div>

            <div class="col-span-1">
                <Checkbox text="Aktīvs" v-model="form.is_active"/>
            </div>
        </div>

        <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
            <template v-if="!loading">
                <button type="button"
                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                        @click.prevent="submit">
                    Saglabāt
                </button>
            </template>
            <template v-else>
                <Loading/>
            </template>

            <button @click.prevent="hideEditItemForm" type="button"
                    class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
                Atcelt
            </button>

        </div>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import Input from "@/components/Components/Input";
import Checkbox from "../../Components/Checkbox";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
    name: "EditInventoryItem",
    components: {
        Input,
        Loading,
        Checkbox
    },
    props: ["item", 'page', 'filter', 'selectedMainBranch'],
    data: () => ({
        showCustomerBranch: false,
        form: null
    }),
    mounted() {
        this.form = this.item
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: 'errors',
        }),
        formValidation() {
            return {
                uuid: {
                    rules: ['required']
                },
                name: {
                    rules: ['required']
                },
            }
        },
    },
    methods: {
        hideEditItemForm() {
            this.$emit('hide-form');
            this.$store.dispatch("clearEditableItem");
        },
        submit() {
            this.$Progress.start();
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateInventoryItem", {
                    data: {
                        id: this.item.id,
                        uuid: this.form.uuid,
                        serial_nr: this.form.serial_nr,
                        name: this.form.name,
                        cost: this.form.cost,
                        is_active: this.form.is_active,
                    },
                    params: {
                        page: this.page,
                        filter: this.filter,
                        selectedBranch: this.selectedMainBranch ? this.selectedMainBranch.id : ''
                    }
                });
            } else {
                this.$Progress.fail();
            }
        },
    },
}
</script>
